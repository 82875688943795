import React, { useEffect, createRef, useState, Fragment } from 'react';
import _ from 'lodash';
import { Card, CardHeader, Button, CardBody, Row, Col, Progress, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import Highcharts from 'highcharts';
import CountUp from 'react-countup';
import cx from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router';

const PROGRESS_CENTRE_ALIGNMENT_MIN_VALUE = 25;

export function WaterSanitationDashboardCardContainer(props) {

    const { wsData, isLoadingWs, downloadWsExcelFileData, userRights: { DASHBOARD } } = props;

    const resDataExportEvent = (e) => {
        e.preventDefault();
        downloadWsExcelFileData();
    }

    console.log(wsData)
    const showChart=wsData?.mode?.length===0

    return (
        <Card className="rounded-0 bg-transparent mb-4">
            <CardHeader className="rounded-0 mb-1 border-bottom d-flex align-items-center justify-content-between dboard-heading">
                <h5 className="display font-weight-bold bg-white mb-0 d-inline-block"><FontAwesomeIcon icon={['fas', 'tint']} />&nbsp; Water & Sanitation</h5>

                {DASHBOARD.WS_DBOARD_EXPORT_BTN && wsData?.total?.total_project>0? (
                    <Fragment>
                        <Button size="sm" color="info" id="export_ws_data_btn" onClick={resDataExportEvent} className="mr-2">
                            <span className="btn-wrapper--icon">
                                <FontAwesomeIcon icon={['fas', 'file-excel']} className="opacity-8 font-size-xs" />
                            </span>
                        </Button>
                        <UncontrolledTooltip popperClassName={'tooltip-info'} placement="top-end" target="export_ws_data_btn">
                            Export Data To Excel
                        </UncontrolledTooltip>
                    </Fragment>
                ) : null}

            </CardHeader>
            <CardBody className="p-1" >
                <Row>
                    <Col md={showChart? 12 : 8} className="pr-2 ws-left-side-card-container ">
                        <NoOfProjectsCountAndAmt {...props} />
                    </Col>
                    {showChart?  null : (
                        <Col md={4} className="pl-2">
                            <Row>
                                <Col md={12} sm={12}>
                                    <Card className="rounded-0 card-box ws-pie-chart-container">
                                        <CardBody className="p-1">
                                            <ModeOfPieCharts {...props} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    )}
                </Row>
            </CardBody>
        </Card>
    );
}


function CardsDetails(props) {

    const { total, amt, title, faicon, isProgress, progressClassName, percent, barSpace, children, progressBarClassName, isLoading } = props;

    let barChartSpace = !_.isNil(barSpace) ? barSpace : true;

    let isTextWrapp = false;
    /***if (!_.isNil(title) && !_.isEmpty(title) && title.toLowerCase() == 'operation and maintenance stage') {
        isTextWrapp = true;
    }***/

    return (
        <Card className={cx("card-box btn px-3 rounded-0 dboard-status-card-box", {"operation-maintenance-stage": isTextWrapp}, children ? "py-3" : "py-4")}>
            <div className="d-flex align-items-center">
                {faicon ?
                    (
                        <div className="pr-3 status-left-content">
                            {isLoading ? <Skeleton width="50px" height="50px" /> : <FontAwesomeIcon icon={faicon} className="text-primary" size="3x" />}
                        </div>
                )
                : null}
                <div className="flex-grow-1 pl-2 status-right-content">
                    <h5 className="border-bottom pb-3 mb-3 font-weight-bold text-left" style={{ fontSize: '0.95rem' }}>{title}</h5>
                        {children ? children : (
                            <Fragment>
                                <div className="d-flex align-items-center pb-4 justify-content-between amt-project-val-container">
                                    <div>
                                    {isLoading ? <Skeleton width="150px" /> : (
                                        <div className="cost-amt-fsize">
                                            <CountUp
                                                start={0}
                                                end={total || 0}
                                                duration={3}
                                                deplay={2}
                                                separator=""
                                                decimals={0}
                                            />
                                        </div>
                                    )}
                                    </div>
                                    <div className="cost-amt-fsize text-second">
                                    {isLoading ? <Skeleton width="150px" /> : <><FontAwesomeIcon icon={['fas', 'rupee-sign']} size="sm" />&nbsp;{amt} </>}
                                    </div>
                                </div>
                            </Fragment>
                        )}

                    {isProgress ? (
                        <div>
                            {isLoading ? <Skeleton width="100%" /> : (<Progress barClassName={progressBarClassName ? progressBarClassName : ''} className={cx("progress-animated-alt box-shadow-none", progressClassName ? progressClassName : '', { 'prelative': percent < PROGRESS_CENTRE_ALIGNMENT_MIN_VALUE ? true : false })} value={percent}><span className="center">{percent}%</span></Progress>)}
                        </div>
                    ) :  barChartSpace ?  (
                        <div style={{height: '1rem'}}>
                            &nbsp;
                        </div>
                    ): null}
                </div>
            </div>
        </Card>
    );
}

function NoOfProjectsCountAndAmt(props) {
    const history=useHistory()
    const { wsData, isLoadingWs } = props;

    const total = _.has(wsData, 'total') ? wsData.total : {};
    const status = _.has(wsData, 'status') ? wsData.status : {};
    const mode = _.has(wsData, 'mode') ? wsData.mode : {};




    const gotTOProject=(e)=>{
        console.log(e)
        if(e?.total_project>0 ||e?.m_mode_id ){
            history.push('/wsprojects',e)
        }
        if(e===true){
            history.push('/wsprojects')
        }
        }

    return (
        <Row>
            <Col xl="4" className="pr-2 pb-3">
            <div  onClick={()=>gotTOProject(Number(total.total_project)>0?true:false)}>
                <CardsDetails title="Projects" faicon={['fas', 'tint']} total={+total.total_project} amt={total.total_project_cost_format} isLoading={isLoadingWs} />
            </div>
            </Col>
            {isLoadingWs ? _.map(_.range(0, 6), (v, indx) => (
                <Col md={4} key={indx}>
                    <CardsDetails isProgress={true} isLoading={true}/>
                </Col>
            )) : _.map(status, (obj, indx) => {
                    let cardColNo = (indx + 2);

                    let isFirstCardCol = false,isLastCardCol = false, isMiddleCardCol = false, isLastRow = false;
                    if (cardColNo % 3 === 0) { //Last Col
                        isLastCardCol = true;
                    }else if (cardColNo % 3 === 1) { //First Col
                        isFirstCardCol = true;
                    } else {
                        isMiddleCardCol = true;
                    }

                    if (cardColNo > 3) {
                        isLastRow = true;
                    }

                    return (
                        <Col md={4} className={cx({ "pb-3": !isLastRow }, { "pr-2": isFirstCardCol }, {"px-2": isMiddleCardCol}, {"pl-2": isLastCardCol})} key={indx}>
                            <div  onClick={()=>gotTOProject(obj)}>
                            <CardsDetails title={obj.short_name} faicon={obj.icon} total={+obj.total_project || 0} amt={obj.total_project_cost_format || 0}  progressBarClassName={obj.colorClassName}  isProgress={true} percent={+obj.percent || 0} isLoading={isLoadingWs} />
                            </div>
                        </Col>
                    );
                }
            )}

            <Col md={12} className="mt-3">
                <CardsDetails title="Mode Of Projects" faicon={['fas', 'cogs']} isLoading={isLoadingWs}>
                    <div className="d-flex align-items-center justify-content-between" style={{paddingBottom: '1.15rem'}}>
                        <div>
                            <div  onClick={()=>gotTOProject(mode.mode_ppp)}>
                            {isLoadingWs ? <Skeleton width="250px" /> : (
                                <Fragment>
                                    <span className="text-black-50" style={{ fontSize: '0.93rem' }}>PPP</span>
                                    <div className="cost-amt-fsize">{_.has(mode, 'mode_ppp') ? mode.mode_ppp.total_project : 0}</div>
                                </Fragment>
                            )}
                         </div>
                        </div>

                        <div>
                        <div  onClick={()=>gotTOProject(mode.mode_ham)}>
                            {isLoadingWs ? <Skeleton width="250px" /> : (
                                <Fragment>
                                    <span className="text-black-50" style={{fontSize: '0.93rem'}}>HAM</span>
                                    <div className="cost-amt-fsize">{_.has(mode, 'mode_ham') ? mode.mode_ham.total_project: 0}</div>
                                </Fragment>
                            )}
                             </div>
                        </div>

                        <div>
                        <div  onClick={()=>gotTOProject(mode.mode_epc)}>
                            {isLoadingWs ? <Skeleton width="250px" /> : (
                                <Fragment>
                                    <span className="text-black-50" style={{fontSize: '0.93rem'}}>EPC</span>
                                    <div className="cost-amt-fsize">{_.has(mode, 'mode_epc') ? mode.mode_epc.total_project: 0}</div>
                                </Fragment>
                            )}
                     </div>

                        </div>
                        <div>
                        <div  onClick={()=>gotTOProject(mode.mode_opr)}>
                            {isLoadingWs ? <Skeleton width="250px" /> : (
                                <Fragment>
                                    <span className="text-black-50" style={{fontSize: '0.93rem'}}>OPR</span>
                                    <div className="cost-amt-fsize">{_.has(mode, 'mode_opr') ? mode.mode_opr.total_project: 0}</div>
                                </Fragment>
                            )}
                     </div>
                     </div>
                    </div>
                </CardsDetails>
            </Col>
        </Row>
    );
}



function ModeOfPieCharts(props) {
    const { wsData } = props;
    const mode = _.has(wsData, 'mode') ? wsData.mode : {};
    useEffect(() => {

        let dataLabelsOptions = {
            enabled: true,
            format: '{point.name}<br/>{point.percentage:.2f} %',
            useHTML: true,
            style: {
                fontSize: '0.70rem',
                fontWeight: '400',
                color: '#3b3e66'
            }
        };

        if ($(window).outerWidth() >= 1200 && $(window).outerWidth() <= 1440) {
            $(container.current).css({ height: '260px', 'min-height': '260px', 'max-height': '260px' });

            dataLabelsOptions = {
                enabled: true,
                distance: 2,
                useHTML: true,
                format: '{point.name}<br/>{point.percentage:.2f} %',
                style: {
                    fontSize: '0.65rem',
                    fontWeight: '400',
                    color: '#3b3e66'
                }
            };

        } else {
            $(container.current).css({ height: ($(".ws-left-side-card-container").outerHeight() - 10) + "px" });
        }

        Highcharts.chart(container.current, {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            credits: {
                enabled: false
            },
            colors: [
                //'#910000', // PPP
                //'#1aadce', //HAM
                //'#492970' // EPC
                '#f28f43', //PPP
                //'#77a1e5', //HAM
                '#1aadce' //EPC
            ],
            title: {
                text: null,
                style: {
                    fontSize: '0.95rem',
                    fontWeight: '600',
                    color: '#3b3e66'
                }
            },
            subtitle: {
                //text: 'Project Mode'
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: dataLabelsOptions
                }
            },
            series: [{
                name: 'Project Mode',
                colorByPoint: true,
                data: [{
                    name: 'PPP',
                    y: _.get(mode, 'mode_ppp.total_project') || 0
                },  {
                    name: 'EPC',
                    y: _.get(mode, 'mode_epc.total_project') || 0
                },  {
                    name: 'HAM',
                    y: _.get(mode, 'mode_ham.total_project') || 0
                },  {
                    name: 'OPR',
                    y: _.get(mode, 'mode_opr.total_project') || 0
                }]
            }]
        });
    }, [mode]);

    const container = createRef();

    return (
        <div ref={container} style={{ width: '100%', minHeight: '288px' }}></div>
    );
}
